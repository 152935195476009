<template>
  <h1>
    Temporarily unavailable
  </h1>
  <!--<VHeader />

  <ProjectBlock
    name="Steam Tank"
    project-link="https://play.google.com/store/apps/details?id=com.Kovalev.Runner&hl=en&gl=US"
    date="2023"
    instruments="Unity, Blender, Photoshop"
    languages="C#"
    features="3D, Android, Camera Angle Change"
  >
    <template v-slot:description>
      <SteamTankDescription />
    </template>
    <template v-slot:animations>
      <SteamTankGifs />
    </template>
    <template v-slot:right-media>
      <SteamTankTrailer />
    </template>
  </ProjectBlock>

  <ProjectBlock
    name="Clonbat"
    project-link="https://play.google.com/store/apps/details?id=com.Air1618.Clonbat&hl=en&gl=US"
    date="2020"
    instruments="Unity, Blender, Photoshop"
    languages="C#"
    features="3D, Android"
  >
    <template v-slot:description>
      <ClonbatDescription />
    </template>
    <template v-slot:animations>
      <ClonbatGifs />
    </template>
    <template v-slot:right-media>
      <ClonbatTrailer />
    </template>
  </ProjectBlock>

  <ProjectBlock
    name="PoggiPlay Games"
    project-link="https://poggiplay.com/"
    date="2019-2024"
    instruments="Vue.js, Pixi.js, Howler.js, Webpack"
    languages="JavaScript, HTML, CSS, SASS, Mermaid, Markdown"
    features="2D, PC, Mobile, Social, 2D Physics"
    :backgroundUrl="poggiPlayBackground"
  >
    <template v-slot:description>
      <PoggiPlayGamesDescription/>
    </template>
    <template v-slot:right-media>
      <PoggiPlayGamesCarousel />
    </template>
  </ProjectBlock>

  <ProjectBlock
    name="Magic Defense (prototype)"
    date="2024"
    instruments="Unity, Blender, Photoshop"
    languages="C#"
    features="3D, PC, Computer Vision, Laser Pointer as Controller"
    :backgroundUrl="magicDefenseBackground"
    ribbonLabel="Innovation"
  >
    <template v-slot:description>
      <MagicDefenseDescription/>
    </template>
    <template v-slot:right-media>
      <MagicDefenseCarousel/>
    </template>
  </ProjectBlock>

  <ProjectBlock
    name="Action Platformer (vertical-slice)"
    date="2023"
    instruments="Unity, Blender, Photoshop"
    languages="C#"
    features="3D, PC, Neural Networks"
  >
    <template v-slot:description>
      <ActionPlatformerDescription />
    </template>
    <template v-slot:animations>
      <ActionPlatformerGifs />
    </template>
    <template v-slot:right-media>
      <ActionPlatformerTrailer />
    </template>
  </ProjectBlock>

  <ProjectBlock
    name="Test Tasks"
    date="2024"
    instruments="Unity, MagicalVoxel, Blender"
    languages="C#"
    features="3D, Android, Destructible environment"
  >
    <template v-slot:description>
      <TestTasksDescription />
    </template>
    <template v-slot:right-media>
      <TestTasksCarousel />
    </template>
  </ProjectBlock>

  <ProjectBlock
    name="Cyberfest"
    instruments="Unity, Blender, Photoshop"
    languages="C#"
    features="3D, Neural Networks, Cellular Automatons"
    :backgroundUrl="cyberfestBackground"
  >
    <template v-slot:description>
      <CyberfestDescription />
    </template>
    <template v-slot:right-media>
      <CyberfestDevblog />
    </template>
  </ProjectBlock>

  <ProjectBlock
    name="Red Falcon"
    project-link="https://store.steampowered.com/app/1402700/_/"
    date="2021"
    instruments="Unity"
    languages="C#"
    features="2D, PC"
    :backgroundUrl="redFalconBackground"
  >
    <template v-slot:description>
      <RedFalconDescription/>
    </template>
    <template v-slot:right-media>
      <RedFalconTrailer/>
    </template>
  </ProjectBlock>

  <ProjectBlock
    name="Call Of Sword (vertical-slice)"
    date="2015"
    instruments="GameMaker, Paint.NET"
    languages="GameMaker language"
    features="2D, Desktops"
    :backgroundUrl="callOfSwordBackground"
    isLast
  >
    <template v-slot:description>
      <CallOfSwordDescription/>
    </template>
    <template v-slot:right-media>
      <CallOfSwordTrailer />
    </template>
  </ProjectBlock>

  <ButtonToTop />-->
</template>

<script>
/*import VHeader from './components/VHeader.vue'
import ButtonToTop from './components/ButtonToTop.vue'
import ProjectBlock from './components/ProjectBlock.vue'

import SteamTankDescription from './components/descriptions/SteamTankDescription.vue'
import SteamTankGifs from './components/media/SteamTankGifs.vue'
import SteamTankTrailer from './components/media/SteamTankTrailer.vue'

import ClonbatDescription from './components/descriptions/ClonbatDescription.vue'
import ClonbatGifs from './components/media/ClonbatGifs.vue'
import ClonbatTrailer from './components/media/ClonbatTrailer.vue'

import PoggiPlayGamesDescription from './components/descriptions/PoggiPlayGamesDescription.vue'
import PoggiPlayGamesCarousel from './components/media/PoggiPlayGamesCarousel.vue'
import poggiPlayBackground from '@/assets/CardBackgrounds/PoggiPlayBackground.jpg'

import MagicDefenseDescription from './components/descriptions/MagicDefenseDescription.vue'
import MagicDefenseCarousel from './components/media/MagicDefenseCarousel.vue'
import magicDefenseBackground from '@/assets/CardBackgrounds/MagicDefenseBackground.jpg'

import ActionPlatformerDescription from './components/descriptions/ActionPlatformerDescription.vue'
import ActionPlatformerGifs from './components/media/ActionPlatformerGifs.vue'
import ActionPlatformerTrailer from './components/media/ActionPlatformerTrailer.vue'

import CallOfSwordDescription from './components/descriptions/CallOfSwordDescription.vue'
import CallOfSwordTrailer from './components/media/CallOfSwordTrailer.vue'
import callOfSwordBackground from '@/assets/CardBackgrounds/CallOfSwordBackground.jpg'

import RedFalconDescription from './components/descriptions/RedFalconDescription.vue'
import RedFalconTrailer from './components/media/RedFalconTrailer.vue'
import redFalconBackground from '@/assets/CardBackgrounds/RedFalconBackground.jpg'

import CyberfestDescription from './components/descriptions/CyberfestDescription.vue'
import CyberfestDevblog from './components/media/CyberfestDevblog.vue'
import cyberfestBackground from '@/assets/CardBackgrounds/CyberfestBackground.jpg'

import TestTasksDescription from './components/descriptions/TestTasksDescription.vue'
import TestTasksCarousel from './components/media/TestTasksCarousel.vue'
*/
export default {
  /*components: {
    VHeader,
    ButtonToTop,
    ProjectBlock,

    SteamTankDescription,
    SteamTankGifs,
    SteamTankTrailer,

    ClonbatDescription,
    ClonbatGifs,
    ClonbatTrailer,

    PoggiPlayGamesDescription,
    PoggiPlayGamesCarousel,

    MagicDefenseDescription,
    MagicDefenseCarousel,

    ActionPlatformerDescription,
    ActionPlatformerGifs,
    ActionPlatformerTrailer,

    CallOfSwordDescription,
    CallOfSwordTrailer,

    RedFalconDescription,
    RedFalconTrailer,

    CyberfestDescription,
    CyberfestDevblog,

    TestTasksDescription,
    TestTasksCarousel,
  },

  setup() {
    return {
      callOfSwordBackground,
      redFalconBackground,
      cyberfestBackground,
      poggiPlayBackground,
      magicDefenseBackground
    }
  }*/
}
</script>

<style>
  html {
    font-size: 2vw;
  }

  body {
    margin: 0;
    color: lightgray;
    font-family: "Gill Sans", sans-serif;
    background-image: url("@/assets/backgroundPattern.png");
    background-repeat: repeat;
  }

  h1 {
    margin-top: 10%;
    text-align: center;
  }
</style>
